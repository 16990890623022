
import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiH5Settings, apiH5SettingsSet } from '@/api/channel/h5_store'
import { copyClipboard } from '@/utils/util'
@Component({
    components: {
        MaterialSelect
    }
})
export default class h5Store extends Vue {
    /** S Data **/
    form = {
        status: 1, // 状态 0-关闭 1-开启
        url: '' // 访问链接
    }

    /** E Data **/

    // 获取APP设置
    getH5Settings() {
        apiH5Settings()
            .then((res: any) => {
                this.form = res
                // this.$message.success('获取数据成功!')
            })
            .catch(() => {
                // this.$message.error('获取数据失败!')
                console.log('数据请求失败!')
            })
    }

    // 修改APP设置
    putH5SettingsSet() {
        apiH5SettingsSet({ status: this.form.status })
            .then((res: any) => {
                this.getH5Settings()
                //this.$message.success('保存成功!')
            })
            .catch(() => {
                //this.$message.error('保存失败!')
            })
    }
    // 复制到剪贴板
    onCopy(value: string) {
        copyClipboard(value)
            .then(() => {
                this.$message.success('复制成功')
            })
            .catch(err => {
                this.$message.error('复制失败')
                console.log(err)
            })
    }

    /** S Life Cycle **/
    created() {
        this.getH5Settings()
    }
    /** E Life Cycle **/
}
